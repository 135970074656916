@import "@/services/styles/constants.scss";

.wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  margin-bottom: 10px;
}

.warningIcon {
  color: $red-basic;
  margin-right: 10px;
}
