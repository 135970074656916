@import "./constants.scss";

:global {
  * {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", Arial, sans-serif;
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }

  .blurred {
    filter: blur(5px) opacity(30%);
  }

  .el-button {
    border-radius: 999px !important;
    min-width: 120px;
    min-height: 30px;
    color: $red-basic !important;
    border: 1px solid;
    border-color: $gray-border !important;
    fill: $red-basic;
    transition: border-color, color, background-color $default-transition-time;

    svg {
      height: 20px;
      vertical-align: middle;

      path {
        transition: fill $default-transition-time;
      }
    }

    &:hover {
      color: $red-light !important;
      fill: $red-light;
      border-color: mix($gray-border, $red-light, 70%) !important;
    }

    &:focus {
      color: $red-basic !important;
      fill: $red-basic;
    }

    &:active {
      color: $red-dark !important;
      fill: $red-dark;
      border-color: mix($gray-border, $red-dark, 60%) !important;
    }
  }

  .el-button--primary {
    border: none !important;
    color: #ffffff !important;
    fill: #ffffff;
    background-color: $red-basic !important;

    &:hover {
      background-color: $red-light !important;
      color: #ffffff !important;
      fill: #ffffff;
    }

    &:focus {
      background-color: $red-basic !important;
      color: #ffffff !important;
      fill: #ffffff;
    }

    &:active {
      background-color: $red-dark !important;
      color: #ffffff !important;
      fill: #ffffff;
    }

    &.is-disabled {
      background-color: desaturate(lighten($red-basic, 20%), 30%) !important;
    }
  }

  .el-input__inner {
    border: 1px solid $gray-border !important;

    &:focus {
      border: 1px solid $red-light !important;
    }
  }

  .el-tabs__header {
    border-bottom: 1px solid lighten($gray-border, 10%) !important;
  }

  .el-tabs__item {
    color: $red-basic !important;
    transition: color $default-transition-time;

    &:hover {
      color: $red-light !important;
    }

    &.is-active,
    &.is-active &:hover {
      color: #000000 !important;
      font-weight: 600;
    }
  }

  .el-tabs__active-bar {
    background-color: $red-basic !important;
  }

  .el-notification {
    border-radius: $default-border-radius !important;
    box-shadow: $default-shadow !important;
  }

  .el-table {
    border-radius: $default-border-radius;
    box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.07);
  }

  .el-table,
  .el-table td,
  .el-table th.is-leaf {
    border: none !important;
  }

  .el-table::before,
  .el-table::after {
    background-color: inherit !important;
  }

  .el-table,
  .el-table th,
  .el-table__header-wrapper thead div {
    background-color: #f9f9f9 !important;
  }

  .el-table .is-center .cell {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .el-table__body-wrapper {
    background-color: $default-background-color;

    .el-table-column--selection {
      .el-checkbox__label {
        padding-left: 0;
      }
    }
  }

  .el-table--enable-row-transition .el-table__body td {
    color: #000000 !important;
    font-size: 13px !important;
    font-weight: 400;
  }

  .el-table__header-wrapper thead div {
    color: $gray-text !important;
    font-size: 13px !important;
    font-weight: 500;
  }

  .el-table__body tr:hover > td {
    background-color: darken($app-background-color, 2%) !important;
  }

  .el-table__expanded-cell {
    background-color: $app-background-color !important;
  }

  .el-dropdown-menu {
    box-shadow: $default-shadow !important;
    border-radius: $default-border-radius !important;
    border: 1px solid rgba(0, 0, 0, 0.04) !important;
  }

  .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: $app-background-color !important;
  }

  .el-dialog {
    border-radius: $default-border-radius !important;
    box-shadow: $default-shadow !important;
  }

  .el-dialog__headerbtn {
    &:hover,
    &:focus {
      .el-dialog__close {
        color: $red-basic !important;
      }
    }
  }

  .el-tag--primary {
    background-color: lighten($red-basic, 50%) !important;
    border-color: lighten($red-dark, 45%) !important;
    color: #000 !important;

    .el-tag__close {
      background-color: lighten($red-dark, 10%) !important;
      font-size: 8px !important;
    }
    .el-tag__close:hover {
      background-color: lighten($red-dark, 6%) !important;
      color: #000 !important;
    }
  }

  .el-switch.is-checked .el-switch__core {
    border-color: $red-basic !important;
    background-color: $red-basic !important;
  }

  .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    color: $red-dark !important;
  }

  .el-select-dropdown:not(.is-multiple) .el-select-dropdown__item.selected {
    background-color: $red-basic !important;

    &:hover {
      background-color: $red-dark !important;
    }
  }

  .el-input-number__decrease:hover,
  .el-input-number__increase:hover {
    color: $red-basic !important;
  }

  .el-textarea__inner:focus {
    border-color: $red-light !important;
  }

  .el-tag--primary {
    background-color: lighten($red-basic, 50%) !important;
    border-color: lighten($red-dark, 45%) !important;
    color: #000 !important;

    .el-tag__close {
      background-color: lighten($red-dark, 10%) !important;
      font-size: 8px !important;
    }
    .el-tag__close:hover {
      background-color: lighten($red-dark, 6%) !important;
      color: #000 !important;
    }
  }

  .el-switch.is-checked .el-switch__core {
    border-color: $red-basic !important;
    background-color: $red-basic !important;
  }

  .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    color: $red-dark !important;
  }

  .el-tag--primary {
    background-color: lighten($red-basic, 50%) !important;
    border-color: lighten($red-dark, 45%) !important;
    color: #000 !important;

    .el-tag__close {
      background-color: lighten($red-dark, 10%) !important;
      font-size: 8px !important;
    }
    .el-tag__close:hover {
      background-color: lighten($red-dark, 6%) !important;
      color: #000 !important;
    }
  }

  .el-switch.is-checked .el-switch__core {
    border-color: $red-basic !important;
    background-color: $red-basic !important;
  }

  .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    color: $red-dark !important;
  }

  .el-checkbox__input {
    &.is-checked,
    &.is-focus,
    &:hover {
      .el-checkbox__inner {
        border-color: $red-basic !important;
      }
    }

    &.is-checked {
      .el-checkbox__inner {
        background-color: $red-basic !important;
      }
    }
  }

  .el-pager li.active {
    border-color: $red-basic !important;
    background-color: $red-basic !important;
  }
}
