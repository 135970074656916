@import "@/services/styles/constants.scss";

.container {
  border-radius: $default-border-radius;
  background: $default-background-color;
  padding: $default-padding;
}

.shadow {
  box-shadow: $default-shadow;
}
