.passwordButton {
  border-radius: 0px !important;
  background-color: transparent !important;
  border: none !important;
  min-width: 30px !important;

  img {
    vertical-align: middle;
  }
}
