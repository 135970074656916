$app-background-color: #f7f7f7;
$default-border-radius: 5px;
$default-background-color: #ffffff;
$default-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.06);
$default-padding-top: 18px;
$default-padding-side: 21px;
$default-margin-side-page: 30px;
$default-padding: $default-padding-top $default-padding-side;
$default-transition-time: 0.2s;

$red-basic: #d0021b;
$red-dark: #b30016;
$red-light: #e62c41;

$orange-basic: #f06000;

$gray-basic: #9b9b9b;
$gray-text: #848484;
$gray-border: #c8c8c8;

@mixin small-form-error-container {
  font-size: 10px;
  padding-top: 1px;
}
