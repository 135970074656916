.page {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  font-size: 23px;
}

.container {
  display: flex;
  min-width: 300px;
  flex-direction: column;
  align-items: center;
  margin-top: 28px;
  padding: 20px 30px 10px 30px;
}

.form {
  width: 100%;
}

.loginButton {
  width: 100%;
  height: 40px;
}
